import mitt from 'mitt'
import type { TRecord } from '~/src/types/common'

interface IEventBus {
  $on: (event: string, handler: Function) => void
  $off: (event: string, _handler?: Function) => void
  $emit: (event: string, ...args: any[]) => void
  _events: TRecord
}

const emitter = mitt()

export const EventBus: IEventBus = {
  $on: emitter.on,
  $off: emitter.off,
  $emit: emitter.emit,
  _events: emitter.all,
}
